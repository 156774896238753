<template id="home"> 
<main>

<div class="grid-sect2 main  ">
  <div class="video-container">

<video class="video-content" autoplay loop muted width="100%" height="100%" >
<source  :src="require ('@/assets/SlideShowVid2.mp4')"  type="video/mp4">


</video>


</div>



</div>
   <!-- <div class=" anchor-btn" > 
  <a class="btn-selection-2" href="#sect-projets"> ... </a>
  </div> -->
  <div id="sect-projets">
  <!-- Composant projet mis ici -->
   <Projets/>
   
</div>
  </main>

</template>

<script>

// @ est un alias de /src

import Projets from '@/views/Projets.vue';



export default {
  
 
  components: {
    Projets,  
  },
  mounted(){ window.scrollTo(0,0)},
  data() {
    return {
      hover: false,
    };
  }
}


</script>

<style lang="css" scoped>
#sect-anchor{
z-index: -20;
}
.anchor-btn{
  display: flex;
  margin: auto;
  grid-area: aside4/main-mid/aside4/main-mid;
}
.container-presentation{
  display: flex;
  justify-content: center;
  align-items: center;
  /*flex-direction: column;*/
  grid-area: aside1/aside1/aside4/main-end;
  padding: 10px 10px 0px 0px;

}


.presentation{

line-height: 1.25;
color: rgba(73, 77, 80, 1);
font-family: "NeueMontreal-regular";
font-weight: lighter;
text-align: center;
font-size: 1rem ;
/* font-weight: lighter; */
padding:0 5px 0 5px ;
}
.line-1{
    color: rgba(73, 77, 80);
    position: relative;
    /* top: 70%;   */
    width: 29em;
    /* margin: 0 auto; */
    border-right: 2px solid rgba(255,255,255,.75);
    font-size: 50%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);    
}

/* Animation */
.anim-typewriter{
  animation: typewriter 4s steps(44) 1s 1 normal both,
             blinkTextCursor 500ms steps(44) infinite both;
}
@keyframes typewriter{
  from{width: 0;}
  to{width: 28em;}
}
@keyframes blinkTextCursor{
  from{border-right-color: rgba(73, 77, 80, 1);}
  to{border-right-color: transparent;}
}


.line{
  content: "";
  display: flex;
  align-self: center;
  height: 2px;
  width: auto;
  grid-area: foot/aside1/foot/main-end;
  /* border-bottom-right-radius: 100px;
   border-top-right-radius: 100px; */
  background:  rgba(73, 77, 80, 1); ;
}
.rounded{
  content: "";
  height: 100px;
  width: 100px;
  display: flex;
  justify-self: end;
  border-radius: 50px;
    grid-area: foot/main-end/foot/main-end;
  background:  rgba(73, 77, 80, 1); ;

}
.main{
  position: relative;
 
}

@media only screen and (max-width: 1600px){
         .grid-sect{
        grid-template-columns: repeat(4, 1fr);
        
        grid-template-areas: 
        "top top top top-end" 
        "aside1 main-row1 main-mid main-end"
         "aside2 main-row2 main-mid main-end" 
         "aside3 main-row3 main-mid main-end"
         "aside4 main-row4 main-mid main-end"
          "foot foot foot foot"
        ;
        } 
        /*---------media finale*/

.container-presentation{
  grid-area: aside1/aside1/aside3/main-end;
}
.presentation{
  text-align: center;
  font-size: 1rem;
  line-height: 1.5;
  
}
.line{
  grid-area: foot/aside1/foot/main-end;
}
.anchor-btn{
  grid-area: aside4/main-row4/aside4/main-mid;
}


}


 @media only screen and (max-width: 1005px){
         .grid-sect{
        grid-template-columns: repeat(3, 1fr);
        
        grid-template-areas: 
        "top top top" 
        "aside1 main-row1 main-end"
         "aside2 main-row2 main-end" 
         "aside3 main-row3 main-end"
         "aside4 main-row4 main-end"
          "foot foot foot"
        ;
        } 

.rounded{
  justify-self: end;
  grid-area: foot/main-end/foot/main-end;
}
.line{
  grid-area: foot/aside1/foot/main-end;
}
 
 .anchor-btn{
  grid-area: aside4/main-row4/aside4/main-row4;
}
.presentation{
  /* font-size: 2.3rem; */
  }
        
}


@media only screen and (max-width: 735px){
         .grid-sect{
        grid-template-columns: repeat(2,1fr);
        
        grid-template-areas: 
        "top top  " 
        "aside1  main-row1"
         "aside2  main-row2" 
         "aside3  main-row3"
         "aside4  main-row4"
          "foot foot  "
        ;
        } 
        .presentation{
          /* font-size: 1.5rem; */
          margin: 0.5rem;
        }
        .container-presentation{
 
 grid-area: aside1/aside1/main-row3/main-row3;
}
        .rounded{
           display: none;
           /*
           justify-self: end;
          grid-area: foot/main-row4/foot/main-row4;*/
        }
        .line{
          display: none;
  /*grid-area: foot/aside1/foot/main-end;*/
} 


        .anchor-btn{
  grid-area: aside4/aside4/aside4/main-row4;
}

}
        
@media only screen and (max-width: 600px) {
          body{
            padding: 0;
          }
        .grid-sect{
        grid-template-columns: 1fr;
        grid-template-rows: 0.2fr 0.5fr repeat(4, .5fr) 0.4fr;
        grid-row-gap: 5px;
        grid-template-areas: 
        "top"
        "aside "
        " main-row1"
        " main-row2"
        "section-row1 "
        "section-row2 "
        "foot"
        ;
        }
        .presentation{
           font-size: 1rem;
        }
.container-presentation{
 
 grid-area: aside/aside/section-row1/aside;
}
              .line{
                
  grid-area: foot;
} 
.rounded, .line{
  display: none;
        }
.anchor-btn{
  grid-area: section-row2;
}

.line-1{
  display: none;
  overflow:unset;
   /* width: 34em; */
  font-size: 45%;
}

.anim-typewriter{
  animation: typewriter none,
             blinkTextCursor none;;
}
}
@media only screen and (max-height: 535px){
  .line-1{
  display: none;
  overflow:unset;
   /* width: 34em; */
  font-size: 45%;
}

.anim-typewriter{
  animation: typewriter none,
             blinkTextCursor none;;
}

        }

</style>