<template>
  <!-------------------------------------------COMPOSANT NAV------------------------------------------->
  <header class="nav-bar">
    <div class="logo-container">
      <a :href="$router.resolve({ name: 'Home' }).href" class="projet-info">
        <img class="logo" src="@/assets/logogentil.png" alt="">
      </a>
    </div>
    
    <nav class="nav-container">
      <ul class="nav-items">
        <li><a :href="$router.resolve({ name: 'Projets' }).href" active-class="active" v-if="renderComponent" class="links">WORKS</a></li>
        <li><router-link v-if="renderComponent" active-class="active" to="/about" class="links">ABOUT</router-link></li>
        <li><router-link v-if="renderComponent" to="/music" class="links" :key="componentKey">MUSIC</router-link></li>
      </ul>
    </nav>
    
    <div class="spacer"></div>
  </header>
</template>

<script>
export default {
  name: 'Navigation',
  data() {
    return {
      renderComponent: true,
    };
  },
  methods: {
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    }
  }
};
</script>

<style>
.nav-bar {
  z-index: 5;
  display: flex;
  align-items: center; /* Center items vertically */
  width: 100%;
  padding: 0.2rem 2rem 0rem 2rem;
  justify-content: space-between; /* Space items evenly */
  position: fixed;
  top: 0;
  background-color: #f2f0f000;
  transition: 0.5s;
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}
.nav-bar:hover {
  background-color: #F2F0F0;
  transition: 0.91s ease-in-out;
}
.logo {
  width: 25px;
}
.logo-container {
  flex: 1; /* Take available space */
}
.nav-container {
  flex: 2; /* Take available space */
  display: flex;
  justify-content: center; /* Center nav items horizontally */
}
.nav-items {
  display: flex;
  justify-content: space-around; /* Space nav items evenly */
  align-items: center;
  padding: 0;
  margin: 0;
}
.links {
  color: #2B2B2B;
  transition: 0.5s;
}
.links:hover {
  border-bottom: solid 3px #2B2B2B;
  transition: 0.2s ease-in-out;
}
.nav-items li:nth-child(2) {
  margin: 0 1.875rem;
}
ul > li {
  list-style: none;
}
.spacer {
  flex: 1; /* Take available space */
}

@media only screen and (max-width: 1005px) {
  .nav-bar {
    background-color: #F2F0F0;
  }
}
@media only screen and (max-width: 805px) {
  /* Additional styles if needed */
}
@media only screen and (max-width: 735px) {
  .nav-bar {
    background-color: #f2f0f0;
    transition: background-color 0.5s;
  }
}
@media only screen and (max-width: 600px) {
  .nav-items {
    font-size: 0.8rem;
  }
  .logo-container {
    /* margin-right: 30px; */
  }
  .logo {
    width: 20px;
  }
  .nav-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav-bar {
    margin: 0;
    padding: 0.2rem 1rem 0rem 1rem;
  }
}
</style>
