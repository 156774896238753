<template>
  <footer class="">
<div class="f-links groupe-link">
    <a class="light soc-links" href="https://www.instagram.com/genetil_/" target="_blank" rel="noopener noreferrer">Instagram</a>
    <a class="light soc-links" href="https://soundcloud.com/genetilmusic" target="_blank" rel="noopener noreferrer">Soundcloud</a>
    <a class="light soc-links" href="https://www.behance.net/Aassvx" target="_blank" rel="noopener noreferrer">Behance</a>
    <!-- <a class="light soc-links" href="https://www.linkedin.com/in/antoine-assanvo/" target="_blank" rel="noopener noreferrer">Linkedin</a> -->
</div>
<div class="name">
© Code & Design by Antoine Assanvo 
    </div>
  </footer>
</template>

<script>
export default {
    name:"Footer"

}
</script>

<style>
.f-links{
    padding-top: 5px;
   height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.soc-links{
    margin: 0 0.5em 0 0.5em;
    font-size: 0.9rem;

}
.groupe-link{
    display: flex;
    align-self: center;
    justify-items: center;
    margin: auto;
   
}
.name{
    font-size: 0.55rem;
  display: inline-flex;
   margin: 0;
width: 100%;
justify-content: center;
}
 @media only screen and (max-width: 600px) {

.soc-links{
    font-size: 0.7rem;
}

 }

</style>