// J'importe mes elements
import Vue from 'vue'
import VueRouter from 'vue-router'
// J'importe mes composants
import Projets from '../views/Projets.vue'
import Home from '../views/Home.vue'
import error from '../components/error.vue'
Vue.use(VueRouter)
//  Je creer mes routes =  mes chemins de navigations entre les pages
const routes = [
  {
    template: 'home',
    path: '/projets',
    name: 'Projets',
    component: Projets,
    meta: {
      reload: true,}
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/music',
    name: 'Music',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Music.vue')
  },


 
  {
    path: '/projets/art/memento_mori',
    name: 'Memento Mori',
    component: () => import('../views/art/Memento_mori.vue')
  },
  {
    path: '/projets/art/gradients',
    name: 'Gradients',
    component: () => import('../views/art/Gradients.vue')
  }, 

  {
    path: '/projets/art/ecolarium',
    name: 'Ecolarium',
    component: () => import('../views/art/Ecolarium.vue')
  },
  {
    path: '/projets/art/sode',
    name: 'Sode',
    component: () => import('../views/art/Sode.vue')
  },
  {
    path: '/projets/art/prometheus',
    name: 'Prometheus',
    component: () => import('../views/art/Prometheus.vue')
  },
  {
    path: '/projets/art/divinity',
    name: 'Divinity',
    component: () => import('../views/art/Divinity.vue')
  },
  {
    path: '/projets/art/bleach',
    name: 'Bleach',
    component: () => import('../views/art/Bleach.vue')
  },
  {
    path: '/projets/art/when-everything-falls',
    name: 'Wef',
    component: () => import('../views/art/Wef.vue')
  },
  {
    path: '/projets/art/duemilacento',
    name: 'Duemilacento',
    component: () => import('../views/art/Duemilacento.vue')
  },

  {
    path: '/projets/art/uncanny',
    name: 'Uncanny',
    component: () => import('../views/art/Uncanny.vue')
  },
  {
    path: '/projets/art/feedback',
    name: 'Feedback',
    component: () => import('../views/art/Feedback.vue')
  },
  {
    path: '/projets/art/erasmus',
    name: 'Erasmus',
    component: () => import('../views/art/Erasmus.vue')
  },
  {
    path: '/projets/art/nergal',
    name: 'Nergal',
    component: () => import('../views/art/Nergal.vue')
  },
  {
    path: '/projets/art/memoire',
    name: 'Memoire',
    component: () => import('../views/art/Memoire.vue')
  },
  
  {
    path: '/projets/art/nikead',
    name: 'NikeAd',
    component: () => import('../views/art/NikeAd.vue')
  },
  {
    path: '/projets/art/Coke',
    name: 'Coke',
    component: () => import('../views/art/Coke.vue')
  },
  {
    path: '/projets/art/Highspeed',
    name: 'Highspeed',
    component: () => import('../views/art/Highspeed.vue')
  },
  {
    path: '/projets/art/Flowerboy',
    name: 'Flowerboy',
    component: () => import('../views/art/Flowerboy.vue')
  },
  {
    path: '/projets/art/Casablanca',
    name: 'Casablanca',
    component: () => import('../views/art/Casablanca.vue')
  },
  {
    path: '/projets/art/Fashionkilla',
    name: 'Fashionkilla',
    component: () => import('../views/art/Fashionkilla.vue')
  },
  {
    path: '/projets/art/Kapla',
    name: 'Kapla',
    component: () => import('../views/art/Kapla.vue')
  },
  {
    path: '/projets/art/ngso',
    name: 'Ngso',
    component: () => import('../views/art/Ngso.vue')
  },
  {
    path: '/projets/art/Mugler',
    name: 'Mugler',
    component: () => import('../views/art/Mugler.vue')
  },

  

  { path: '/404', component: error }, 
  { path: '*', redirect: '/404' }, 
  


]

const router = new VueRouter({
  scrollBehavior: function (to /*, from, savedPosition*/) {
    /*if (savedPosition) {
      return savedPosition;
     }else {
        const position = {};
        if (to.hash){
          position.selector= to.hash;
          return false;
        }
      }*/ 

      if (to.hash) {
        return {
          selector: to.hash
          // , offset: { x: 0, y: 10 }
        }
      }
    },
  
  // la navigation dans l'URL est mis en mode Historiqe , cad qu'on peut ecrire le nom d'une page avec un /... à l'inverse d'un #..

  mode: 'hash',
  base: process.env.BASE_URL,
  routes, 
});



// J'exporte 
export default router
