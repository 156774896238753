<template>
<!-------------------------------------------PAGE PROJET------------------------------------------->
<main >
  <section class="main grid-gallery section-projet">
 <!-------- partie boutons----------->
 <!-- <div class="btn-container" data-aos="fade-up" >

      <router-link-active class="btn-selection" to="/projets"> All <sup>16</sup> </router-link-active>

<a :href="$router.resolve({name: 'Art'}).href" class="btn-selection"> Art <sup>14</sup></a>
      <a :href="$router.resolve({name: 'Web design'}).href" class="btn-selection"> Web Design <sup>2</sup></a>
  </div>  -->

<!-- Projet perso: Memento mori-->
<!-- <div class="case MementoMori-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Memento Mori'}).href" class="projet-info">
    <h2 class="mini-p-title">Memento Mori</h2> 
</a>
</div> -->



<!-- Projet perso: gradients
<div class="case Gradients-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Gradients'}).href" class="projet-info">
    <h2 class="mini-p-title">التدرجات</h2> 
</a>
</div> -->

<!-- Projet perso: ecolarium-->
<!-- <div class="case Ecolarium-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Ecolarium'}).href" class="projet-info">
    <h2 class="mini-p-title">Ecolarium</h2> 
</a>
</div> -->
<!-- Projet perso: Sode-->
 <!-- <div class="case Sode-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Sode'}).href" class="projet-info">
    <h2 class="mini-p-title">SŌDĒ</h2> 
</a>
</div>  -->

<!-- Projet perso: Uncanny-->
<!-- <div class="case Uncanny-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Uncanny'}).href" class="projet-info">
    <h2 class="mini-p-title">Uncanny</h2> 
</a>
</div> -->

<!-- Projet perso: When Everything Falls-->
<!-- <div class="case Wef-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Wef'}).href" class="projet-info">
    <h2 class="mini-p-title">W.E.F</h2> 
</a>
</div> -->

<!-- Projet perso: Erasmus-->
<!-- <div class="case Erasmus-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Erasmus'}).href" class="projet-info">
    <h2 class="mini-p-title">Erasmus - Germany </h2> 
</a>
</div> -->
<!-- Projet perso: Nergal-->
<!-- <div class="case Nergal-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Nergal'}).href" class="projet-info">
    <h2 class="mini-p-title">Nergal </h2> 
</a>
</div> -->




<!-- Projet perso: NIKE AD-->
<div class="case NikeAd-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'NikeAd'}).href" class="projet-info">
    <h2 class="mini-p-title">NIKE - campaign </h2> 
</a>
</div>

<!-- Projet perso: Feedback-->
<div class="case Feedback-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Feedback'}).href" class="projet-info">
    <h2 class="mini-p-title">Feedback</h2> 
</a>
</div>

<!-- Projet perso: N.G.SO -->
<div class="case Ngso-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Ngso'}).href" class="projet-info">
    <h2 class="mini-p-title">N.G.SO </h2> 
</a>
</div>


<!-- Projet perso: Memoire-->
<div class="case Memoire-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Memoire'}).href" class="projet-info">
    <h2 class="mini-p-title">Digital Sublime </h2> 
</a>
</div>

<!-- Projet perso: Coke-->
<div class="case Coke-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Coke'}).href" class="projet-info">
    <h2 class="mini-p-title">COKE </h2> 
</a>
</div>

<!-- Projet perso: HighSpeed-->
<div class="case Highspeed-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Highspeed'}).href" class="projet-info">
    <h2 class="mini-p-title">HIGHSPEED </h2> 
</a>
</div>

<!-- Projet perso: Flower Boy-->
<div class="case Flowerboy-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Flowerboy'}).href" class="projet-info">
    <h2 class="mini-p-title">FLOWER BOY</h2> 
</a>
</div>

<!-- Projet perso: Casablanca-->
<div class="case Casablanca-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Casablanca'}).href" class="projet-info">
    <h2 class="mini-p-title">CASABLANCA PARIS</h2> 
</a>
</div>

<!-- Projet perso: Fashion Killa-->
<div class="case Fashionkilla-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Fashionkilla'}).href" class="projet-info">
    <h2 class="mini-p-title">FASHION KILLA </h2> 
</a>
</div>

<!-- Projet perso: Kapla -->
<div class="case Kapla-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Kapla'}).href" class="projet-info">
    <h2 class="mini-p-title">KAPLA - IDENTY</h2> 
</a>
</div>
<!-- Projet perso: Prometheus-->
<div class="case Prometheus-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Prometheus'}).href" class="projet-info">
    <h2 class="mini-p-title">Prometheus</h2> 
</a>
</div>
<!-- Projet perso: Divinity-->
<div class="case Divinity-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Divinity'}).href" class="projet-info">
    <h2 class="mini-p-title">Divinity</h2> 
</a>
</div>

<!-- Projet perso: Duemilacento-->
<div class="case Duemilacento-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Duemilacento'}).href" class="projet-info">
    <h2 class="mini-p-title">Duemilacento</h2> 
</a>
</div>



<!-- Projet perso: Bleach-->
<div class="case Bleach-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Bleach'}).href" class="projet-info">
    <h2 class="mini-p-title">Bleach</h2> 
</a>
</div>



<!-- Projet perso: Mugler -->
<div class="case Mugler-projet projet-container" data-aos="fade-up">
 <a :href="$router.resolve({name: 'Mugler'}).href" class="projet-info">
    <h2 class="mini-p-title">Mugler</h2> 
</a>
</div>

</section>

  <!-- Coposant projet mis ici -->
   <Footer/>
   

</main>

<!-- Composant projets.vue -->
</template>

<script>
import Footer from '@/components/footer.vue';
export default {
    name: "Projets",
    components:{
      Footer,
    },
mounted(){ window.scrollTo(0,0)},
}
</script>

<style lang="css" scoped>

/*Style des projets*/

/* .case{

height: initial;
width: auto;
} */




.Mugler-projet{
background-image:url('~@/assets/Mugler/MGshow.jpg');
grid-area: box18; 
}

.Duemilacento-projet{ 
background-image:url('~@/assets/Duemilacento/leoSquare.jpg');
grid-area: box14; }

.Kapla-projet{ 
background-image:url('~@/assets/Kapla/cover3.jpg');
grid-area: box13; }

.Bleach-projet{ 
background-image:url('~@/assets/Bleach/katanaG.jpg');
grid-area: box12; }
.Prometheus-projet{ 
background-image:url('~@/assets/Prometheus/Typev2.png');
grid-area: box11; }

.Divinity-projet{ 
background-image:url('~@/assets/Divinity/Apollo.jpg');
grid-area: box10; }

.Fashionkilla-projet{
background-image: url('~@/assets/Fashionkilla/BOYisaGUNV3.jpg');
grid-area: box9; 
}

.Casablanca-projet{ 
background-image:url('~@/assets/Casablanca/CASAGradPrint4.jpg');
grid-area: box8; }

.Ngso-projet{
background-image:url('~@/assets/Ngso/HorseProfil.jpg');
grid-area: box7; 
}
.Flowerboy-projet{ 
background-image:url('~@/assets/Flowerboy/FOOT_EXP2.jpg');
grid-area: box6; }
.Highspeed-projet{ 
background-image:url('~@/assets/Highspeed/HSFrame1.jpg');
grid-area: box5; }
.Coke-projet{
background-image:url('~@/assets/Coke/coca2.jpg');
grid-area: box4; 
}
.Memoire-projet{ 
background-image:url('~@/assets/Memoire/CanvasMems.jpg');
grid-area: box3; }
.Feedback-projet{ 
background-image:url('~@/assets/Feedback/Landlogo.jpg');
grid-area: box2; }

.NikeAd-projet{ 
background-image:url('~@/assets/Nike/banniere.jpg');
grid-area: box1; }

/* ---------------------Les boutons----------------------*/
/* .btn-selection:nth-child(2){
  margin:0 1.875rem 0 1.875rem;
}
.btn-selection{
  display: block;
  color:#F2F0F0;
   font-family: "NeueMontreal-bold"; 
   font-size: 1.2rem;
 text-align: center;
background-color: #44494D; 
border-radius: 7px;
padding: 1em;
width: 7.5rem;
}
.btn-container{
  display: flex;
  grid-area: top/main-row1/top/main-end1;
  justify-content: space-around;
align-items: center;
 margin: 30px auto 30px auto;
} */

/* Création du grid "Gallery "  */
/* Grid pour les ecrans > 1600px */
.grid-gallery{
  min-height: calc(100vh - 50px); 
        display: grid;
        position: relative;
        grid-template-columns: repeat(9, 1fr);
        grid-template-rows:  0.5fr repeat(6, 1fr) 0.5fr;
        grid-gap: 0.5rem;
        
        grid-template-areas: 
        /* "top top top  top"  */
        "top1 top2 top3 top4 top5 top6 top7 top8 top9"
        "col-l box1 box2 box3 box4 box5 box6 box7  col-r"
        "col-l box8 box9 box10 box11 box12 box13 box14 col-r"
        "col-l box15 box16 box17 box18 box19 box20 box21 col-r" 
         "col-l box22 box22 box23 box24 box25 box26 box27 col-r" 
        "col-l box28 box29 box30 box31 box32 box33 box34 col-r"
        "col-l box35 box36 box37 box38 box39 box40 box41 col-r"
        "foot1 foot2 foot3 foot4 foot5 foot6 foot7 foot8 foot9"  
        ;
        
        }

/* repeat(2,1fr) repeat(2,0.5fr)repeat(2,1fr) A faire !! */

@media only screen and (max-width: 1600px){
 /* Grid pour les ecrans < 1600px */ 
         .grid-gallery{
           grid-gap: 0.5rem;
        grid-template-columns: repeat(2,1fr) repeat(5,1fr) repeat(2,1fr) ;
    

        } 




/* .cgi-projet{
  display: flex;
  
   grid-area: aside1/main-row1/aside1/main-row1;

} */
/* .waiting-projet{
  
  grid-area: main-end1;
} */



        }


@media only screen and (max-width: 1105px){
 /* Grid pour les ecrans < 1005px */ 
          .grid-gallery{
           grid-gap: 0.4rem;
  
        } 




/* .cgi-projet{
  display: flex;
  
   grid-area: main-row1;
 
}*/
/* .waiting-projet{
  
  grid-area: main-end1;
} */




        }




@media only screen and (max-width: 805px){
  .grid-gallery {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem; /* Ajouté pour le remplissage sur les côtés */
  }

.projet-container{
  margin-bottom: 0.5rem;
}
.btn-container{
  grid-area: top/main-row1/top/main-end1;
}


        }

@media only screen and (max-width: 630px) {
          body{
            padding: 0;
          }
  /* Grid pour les ecrans < 630px */
        .grid-gallery{
        grid-template-columns: 1fr;
        grid-template-rows:   repeat(16, .5fr) 0.01fr;
        grid-row-gap: 0.3rem;
        grid-template-areas: 
        /* "top1" */
        "box1 "
        "box2 "
        "box3 "
        "box4 "
        "box5 "
        "box6 "
        "box7 "
        "box8 "
        "box9 "
        "box10 "
        "box11"
        "box12"
        "box13"
        "box14"
        "box15"
        "box16"
        "box17"
        "box18"
        "box19"
        "box20"
        "box21"
        "box22"
        "box23"
        "box24"
        "box25"
        "box26"
        /* "box27" */
        "foot1"
        ;
        }

.btn-selection:nth-child(2){
  margin:0 1rem 0 1rem;
}
.btn-container{
 grid-area: top;
padding: 0 0.2rem 0 0.2em;

}
.btn-selection{
  font-size: 0.65rem;
  width: 4.5rem;
 
}

/* .cgi-projet{
 
  
  grid-area: main-row1;

}
.waiting-projet{
  
  grid-area: main-end1;
}

.genetil-id{

 

  grid-area: main-row2;
}*/

 } 

.section-projet{
  height: auto;
}

</style>