import Vue from 'vue'
import App from './App.vue'
import VueCookies from 'vue-cookies';
// import VueImg from 'v-img';
Vue.use(VueCookies);
// Vue.use(VueImg);
import router from './router'
import '@/assets/css/style.css'
  Vue.config.productionTip = false
 
//  import style
new Vue({
  router,
  
  render: h => h(App)
}).$mount('#app'); 