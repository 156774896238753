<template>
<section>
  <div class="error-page">
      <h1 class="gras error-title"> :/ DÉSOLÉ 🛠</h1>
     
  </div>
   <Footer/> 
  </section>
</template>

<script>
import Footer from "@/components/footer.vue"
export default {
components:{
  Footer,
}
}
</script>

<style scoped>
.error-page{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-size: 5rem;
    height: 100vh;
}
@media only screen and (max-width: 735px){
.error-title{
  font-size: 4rem;
}
}
</style>