// Le App esy le conteneur de toutes mes pages c'est le BODY de vue, j'injecte à l'interieur l'essentiel des mes pages 
<template>
  <div id="app" >
    <!-- <Alert v-if="showAlert" @closeAlert="closeAlert" /> -->

    <!-- tete = ma navbar je le mets en haut car il vient se placer avant les pages, je le met directement ici car c'est un composant qui reste dans toutes mes pages donc je le met que une fois ici -->
    <tete/>
  <!-- router-view contient mes pages= mes view. c'est view sont navigable grâce au fichier "index.j" où j'ai crée mes routes -->
    <router-view />
    <!-- je met le footer qui va rester sur toutes les pages du portfolio -->
    <!-- <Footer/> -->
  </div>
</template>

<script>
import Vue from 'vue';
import VueImg from 'v-img';
Vue.use(VueImg)
// import Alert from './components/Alert.vue';

import '@/assets/css/style.css'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..

    AOS.init({
      offest: 500,
      duration: 2000
    });

// J'importer le composant navbar
import Navigation from "@/components/navbar.vue"
// import Footer from "@/components/footer.vue"
export default {

  name:"App",
   created () {
            document.title = "GENETIL";
           
        },

  components:{
    // je renomme le composant en tete
  'tete':Navigation,
    // Alert,
  // Footer,
  },  data() {
    return {
      // showAlert: false,
    };
  },
  mounted() {
    // Vérifiez si le cookie 'alertShown' est défini
    // if (!this.$cookies.get('alertShown')) {
    //   this.showAlert = true;
    // }
  },
  methods: {
    // closeAlert() {
    //   // Fermez l'alerte et définissez le cookie pour indiquer qu'elle a été vue
    //   this.showAlert = false;
    //   this.$cookies.set('alertShown', 'true', { expires: '1D' }); // Cookie expire après 1 jour
    // },
  }, 
};
</script>
// Le style present sur App.vue  affecte toutes les autres pages du site, je peux les ecraser en écrivant la même classe et en mettant de nouvelles valeurs dedant.
<style>

 
/* Les fonts que je vais utiliser  */

/* LIGHT font-family: "NeueMontreal-light"; */
@font-face {
    font-family: "NeueMontreal-light";
    src: 
    
        url("../src/assets/NeueMontreal/neuemontreal-light.otf") format("opentype"),
        url("../src/assets/NeueMontreal/neuemontreal-light.woff") format("woff"),url("../src/assets/NeueMontreal/neuemontreal-light.woff2") format("woff2");
  }
  /* MEDIUM font-family: "NeueMontreal-medium"; */
  @font-face {
    font-family: "NeueMontreal-medium";
   src: 
      url("../src/assets/NeueMontreal/neuemontreal-medium.otf") format("opentype"),
        url("../src/assets/NeueMontreal/neuemontreal-medium.woff") format("woff"),
        url("../src/assets/NeueMontreal/neuemontreal-medium.woff2") format("woff2");
  }
  /* BOLD font-family: "NeueMontreal-bold"; */
  @font-face {
    font-family: "NeueMontreal-bold";
   src:  url("../src/assets/NeueMontreal/neuemontreal-bold.otf") format("opentype"),
        url("../src/assets/NeueMontreal/neuemontreal-bold.woff") format("woff"),
        url("../src/assets/NeueMontreal/neuemontreal-bold.woff2") format("woff2");
  }
  /* REGULAR font-family: "NeueMontreal-regular"; */
   @font-face {
    font-family: "NeueMontreal-regular";
   src:   url("../src/assets/NeueMontreal/neuemontreal-regular.otf") format("opentype"),
        url("../src/assets/NeueMontreal/neuemontreal-regular.woff") format("woff"),
        url("../src/assets/NeueMontreal/neuemontreal-regular.woff2") format("woff2");
        
        }
/* --------------------------------------------------------------- */
        #app{
          height: 100dvh;
          height: 100svh;
          width: 100svw;
          width: 100dvw;
          /* position: relative; */
          
          font-size: 1rem;
          font-family: "NeueMontreal-regular";
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
         
        }
        

</style>
